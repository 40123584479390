import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import api, { url } from "../api";
import ReactMarkdown from "react-markdown";
import { Button, Skeleton } from "antd";
import { navigate } from "gatsby";
import rocket from "../images/rocket.png";
import diamond from "../images/diamond.png";
import lamp from "../images/lamp.png";
import { AuthContext } from "../context/GlobalContextProvider";
import dream from "../images/dream-icon.png";
import club from "../images/club-icon.png";
import exclusivity from "../images/exclusivity-icon.png";

function Institucional() {
  const context = useContext(AuthContext);
  const [page, setPage] = useState();
  useEffect(() => {
    !page && getPage();
  }, []);

  const getPage = async () => {
    const { data } = await api.get("/sobre");
    localStorage.setItem("sobre", JSON.stringify(data));
    !page && setPage(data);
  };
  return (
    <Layout
      aboveFooter
      loading={false}
      titlePage="Bivver Empreendimentos"
      pageDesctiption="Institucional"
      padding={false}
    >
      <SEO
        keywords={[`Bivver`, `Empreendimentos`, `imobiliários`, `praia`, `prédios`]}
        title="Institucional"
      />
      {context?.user && (
        <Button
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/sobre`)}
        >
          Editar
        </Button>
      )}
      <div>
        <div className="w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 my-4 self-center">
          <Skeleton loading={!page}>
            <div className="mb-10">
              <ReactMarkdown
                source={page?.quemSomos}
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p name="a" className="text-2xl mb-6" {...props} />
                  ),
                }}
              />
            </div>
          </Skeleton>
          <img className="lg:w-4/5" src={`${url}${page?.imagem?.url}`} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          <h2
            className="md:w-1/3"
            style={{
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Nossas Linhas:
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 px-2 md:px-64 py-3 gap-8 md:gap-36">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px 18px",
            }}
          >
            <img src={dream} width="120px" />
            <p
              className="text-xl mb-6 opacity-75"
              style={{ textAlign: "justify" }}
            >
              {page?.bivverDream}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px 18px",
            }}
          >
            <img src={club} width="120px" />
            <p
              className="text-xl mb-6 opacity-75"
              style={{ textAlign: "justify" }}
            >
              {page?.bivverClub}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px 18px",
            }}
          >
            <img src={exclusivity} width="120px" />
            <p
              className="text-xl mb-6 opacity-75"
              style={{
                textAlign: "justify",
              }}
            >
              {page?.bivverExclusivity}
            </p>
          </div>
        </div>

        <div
          className="grid grid-cols-1 md:grid-cols-3 px-2 md:px-64 py-3 gap-8 md:gap-48"
          style={{ backgroundColor: "#D27A34" }}
        >
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px 18px",
              borderRadius: "32px",
            }}
          >
            <img src={rocket} width="32px" />
            <h2 id="missao" className="mb-3">
              Missão
            </h2>
            <Skeleton loading={!page}>
              <div>
                <ReactMarkdown
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    paragraph: (props) => (
                      <p
                        name="a"
                        className="text-2xl mb-6 opacity-75"
                        style={{ textAlign: "center" }}
                        {...props}
                      />
                    ),
                  }}
                  source={page?.missao}
                />
              </div>
            </Skeleton>
          </div>
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              borderRadius: "32px",
              alignItems: "center",
              padding: "8px 18px",
            }}
          >
            <img src={lamp} width="32px" />
            <h2 id="visao" name="visao" className="mb-3">
              Visão
            </h2>
            <Skeleton loading={!page}>
              <div>
                <ReactMarkdown
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    paragraph: (props) => (
                      <p
                        name="a"
                        className="text-2xl mb-6 opacity-75"
                        style={{ textAlign: "center" }}
                        {...props}
                      />
                    ),
                  }}
                  source={page?.visao}
                />
              </div>
            </Skeleton>
          </div>
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              borderRadius: "32px",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px 18px",
            }}
          >
            <img src={diamond} width="32px" />
            <h2 id="valores" name="valores" className="mb-3">
              Valores
            </h2>
            <Skeleton loading={!page}>
              <div>
                <ReactMarkdown
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    paragraph: (props) => (
                      <p
                        name="a"
                        className="text-2xl mb-6 opacity-75"
                        style={{ textAlign: "center" }}
                        {...props}
                      />
                    ),
                  }}
                  source={page?.valores}
                />
              </div>
            </Skeleton>
          </div>
        </div>
        <div className="w-4/5 mx-auto">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "32px",
              marginTop: "64px",
            }}
          >
            <h2
              className="md:w-1/3"
              style={{
                textAlign: "center",
                paddingBottom: "12px",
                borderBottomWidth: "1.5px",
                borderBottomColor: "black",
              }}
            >
              Política de qualidade:
            </h2>
          </div>
          <Skeleton loading={!page}>
            <div>
              <ReactMarkdown
                renderers={{
                  // eslint-disable-next-line react/display-name
                  paragraph: (props) => (
                    <p
                      name="a"
                      className="text-2xl mb-6 opacity-75"
                      {...props}
                    />
                  ),
                }}
                source={page?.compromissoSocial}
              />
            </div>
          </Skeleton>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={`${url}${page?.pbqpLogo?.url}`} width="7%" />
            <img src={`${url}${page?.isoLogo?.url}`} width="7%" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "16px",
            marginTop: "64px",
          }}
        >
          <h2
            className="md:w-1/3"
            style={{
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Depoimentos:
          </h2>
        </div>
        <p
          name="a"
          className="text-2xl mb-6 opacity-75"
          style={{ textAlign: "center" }}
        >
          Veja o que nossos clientes estão dizendo
        </p>
        <div className="flex gap-32">
          {page?.depoimentos.map((d) => (
            <video
              key={d.id}
              zIndex={10}
              onClick={(e) => e.stopPropagation()}
              className="mx-auto"
              style={{ borderRadius: "16px" }}
              width="540"
              height="480"
              autoPlay
              controls
              muted
              preload={`${process.env.API_URL}${d.url}`}
              src={`${process.env.API_URL}${d.url}`}
            ></video>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default Institucional;
